import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import "./fonts/default.css";
import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";
import AboutPage from "./pages/AboutPage";
import MedistepPage from "./pages/MedistepPage";
import GaitStudioPage from "./pages/GaitStudioPage";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import IRPage from "./pages/IRPage";

const THEME = createTheme({
  typography: {
    fontFamily: `"Inter", "Pretendard", "Helvetica", "Arial", sans-serif`,
  },
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      {/* <Router> */}
      <HashRouter>
        <Routes>
          <Route path="/" exact element={<MainPage />} />
          <Route path="/about" exact element={<AboutPage />} />
          <Route path="/news" exact element={<NewsPage />} />
          <Route path="/ir" exact element={<IRPage />} />
          <Route path="/product/medistep" exact element={<MedistepPage />} />
          <Route
            path="/product/gaitstudio"
            exact
            element={<GaitStudioPage />}
          />
        </Routes>
        {/* </Router> */}
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
