import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Footer from "../components/Footer";
import ScrollToTopBtn from "../components/ScrollTopBtn";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";

export const newsList = [
  {
    imgSrc: "/news_23_03_02.png",
    link: "https://www.veritas-a.com/news/articleView.html?idxno=448646",
    title:
      "고려대 세종캠 LINC3.0사업단 스마트시티즌 위한 헬스케어 솔루션 관련 포럼 개최",
  },
  {
    imgSrc: "/news_23_06_27.png",
    link: "https://www.aitimes.com/news/articleView.html?idxno=152026",
    title: "[AI&빅데이터쇼] 에이트스튜디오, 파킨슨병 자가진단하는 AI 앱 소개",
  },
  {
    imgSrc: "/news_23_10_22.png",
    link: "https://www.aitimes.com/news/articleView.html?idxno=154454",
    title: "에이트스튜디오, 파킨슨병 진단하는 모바일 앱 출시한다",
  },
  {
    imgSrc: "/news_23_10_23.jpeg",
    link: "https://www.sedaily.com/NewsView/29W2K8YK2U",
    title: "SBA, 오픈이노베이션 데모데이 ‘IMPACT DIVE 2023’ 개최",
  },
  {
    imgSrc: "/news_23_11_20.jpeg",
    link: "https://www.seoul.go.kr/seoul/mediahub_view.do?articleNo=2009588",
    title:
      "돌봄로봇부터 AI안부전화까지, 세상 가장 따뜻한 최신 기술을 한자리에!",
  },
  {
    imgSrc: "/news_23_11_22.jpeg",
    link: "https://www.mk.co.kr/news/it/10880891",
    title: "에이트스튜디오, AI로 노인의 퇴행성 질환 진단",
  },
  {
    imgSrc: "/news_23_11_27.jpeg",
    link: "https://blog.naver.com/ks_enter/223275924839",
    title:
      "고려대 세종캠퍼스, 스마트시티 지능형 헬스케어 연구 센터 성과 교류회 및 12개 헬스케어 기업과 산학 MOU 체결",
  },
  {
    imgSrc: "/news_23_12_08.jpg",
    link: "https://www.etnews.com/20231206000020",
    title: "[ESG 스타트-UP in 성수 22] 에이트스튜디오",
  },
  {
    imgSrc: "/news_24_05_28.jpeg",
    link: "https://www.globalnewsagency.kr/news/articleView.html?idxno=331807",
    title: "강남구, ‘제2회 강남 디지털을 품다’ 개최",
  },
  {
    imgSrc: "/news_24_06_04.jpeg",
    link: "https://www.kita.net/board/photoNews/photoNewsDetail.do;JSESSIONID_KITA=4CC4933CDEE3B7537F389E744D180989.Hyper?no=11534",
    title: "제2회 강남, 디지털을 품다",
  },
  {
    imgSrc: "/news_24_06_20_1.jpeg",
    link: "https://www.seongdongnews.com/news/articleView.html?idxno=30779",
    title: "송정노인복지관, AI 기술로 어르신 보행분석 ‘건강관리’",
  },
  {
    imgSrc: "/news_24_06_20_2.jpeg",
    link: "http://www.thebestnews.co.kr/bbs/board.php?bo_table=news&wr_id=48742",
    title: "송정동노인복지관－에이트스튜디오 (주), 업무협약 체결",
  },
  {
    imgSrc: "/news_24_06_20_3.png",
    link: "https://www.bokjitimes.com/news/articleView.html?idxno=37845",
    title: "인공지능 보행 분석 통해 어르신의 건강한 노후 지원한다",
  },
  {
    imgSrc: "/news_24_06_21.jpeg",
    link: "http://www.gangnamnews.net/news/articleView.html?idxno=15064",
    title:
      "논현노인종합복지관, 에이트스튜디오와 보행측정장비 지원업무 협약 체결",
  },
  {
    imgSrc:
      "https://www.sd.go.kr/DATA/bbs/557/2024/07/04/t_20240704135328007C3G03422BCDG07FG.jpg",
    link: "https://www.sd.go.kr/pr/selectBbsNttView.do?key=4216&bbsNo=557&nttNo=338669&searchCtgry=&searchCnd=&searchKrwd=&pageIndex=9#n",
    title: "스마트 포용 「건강·돌봄 일번지, 성동」행사",
  },
  {
    imgSrc: "/news_24_09_10.jpeg",
    link: "https://www.etnews.com/20240906000033",
    title: "[WSCE 2024]에이트스튜디오-AI기반 보행분석계 '메디스텝'",
  },
].reverse();

function News() {
  return (
    <Box className="news pageContent">
      <Grid container spacing={2}>
        {newsList.map((item, idx) => (
          <Grid item xs={6} md={4} xl={4} key={idx}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <Box className="newsCard">
                <Box
                  className="thumbnail"
                  sx={{ backgroundImage: `url(${item.imgSrc})` }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography className="caption">{item.title}</Typography>
                  <OpenInNewIcon style={{ color: "#000", fontSize: "1em" }} />
                </Box>
              </Box>
            </a>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default function NewsPage() {
  const { t } = useTranslation();

  return (
    <Box className="newsPage">
      <Header />
      <PageTitle text={t("news")} />
      <News />
      <Footer />
      <ScrollToTopBtn />
    </Box>
  );
}
